<template>
  <div>
    <Header>3D预览</Header>
    <div class="box">
      <iframe :src="arr" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arr: '',
    };
  },
  mounted() {
    this.arr = this.$route.query.id;
    // window.location.href = arr;
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
